import React, { useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import Recaptcha from "react-google-recaptcha";

import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import { RiArrowRightUpLine } from "react-icons/ri";

import Fade from "react-reveal/Fade";

import { FormValidation } from "calidation";
import axios from "axios";
import * as qs from "query-string";

import { Element as ScrollTarget, Link as ScrollLink } from "react-scroll";

import { StickyContainer, Sticky } from "react-sticky";

import PAGE_CONTENTS from "../../content/pages/contact.json";

const USED_STATES = [];
const USED_PROVINCES = [];

const REP_PROVINCES = (PAGE_CONTENTS.salesRepLocator || []).map(
  (repSection) => {
    repSection.provinces = (repSection.provinces || []).map((province) => {
      const [country, abbreviation, name] = province.split(" - ");

      const provinceData = {
        abbreviation,
        name
      };

      if (country === "Canada") {
        USED_PROVINCES.push(provinceData);
      } else {
        USED_STATES.push(provinceData);
      }

      return provinceData;
    });

    return repSection;
  }
);

const RECAPTCHA_KEY = "6LeEfb8eAAAAAM39rRYQi4uqDiUx-VEMO5e28aNK";

function Contact({ location }) {
  const recaptchaRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [repLocatorCountry, setRepLocatorCountry] = useState("All");
  const [repLocatorStateProvince, setRepLocatorStateProvince] = useState("All");

  const updateCountry = (newValue) => {
    setRepLocatorStateProvince("All");

    setRepLocatorCountry(newValue);
  };

  const handleSubmit = ({ fields, errors, isValid }) => {
    if (isValid) {
      setLoading(true);

      const recaptchaValue = recaptchaRef.current.getValue();

      const axiosOptions = {
        url: location.pathname,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify({
          "form-name": "contact",
          "g-recaptcha-response": recaptchaValue,
          ...fields
        })
      };

      // Submit to Netlify
      axios(axiosOptions)
        .then((response) => {
          setLoading(false);
          setSuccess(true);
          setError(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
  };

  const formItems = [
    {
      title: "Name",
      name: "name"
    },
    {
      title: "Email",
      name: "email"
    },
    {
      title: "Subject",
      name: "subject"
    },
    {
      title: "Tell us about your inquiry",
      name: "message",
      type: "textarea"
    }
  ];

  const config = {
    name: {
      isRequired: "Please fill in your name"
    },
    email: {
      isRequired: "Please enter your email address",
      isEmail: "Email address seems to be invalid"
    },
    subject: { isRequired: "Please write a subject line" },
    message: {
      isRequired: "Please write a message"
    }
  };

  const salesRepsToShow = REP_PROVINCES.filter(
    (repProvince) =>
      (repLocatorCountry === "All" ||
        repProvince.country === repLocatorCountry) &&
      (repLocatorStateProvince === "All" ||
        repProvince.provinces.find(
          (PROVINCE) => repLocatorStateProvince === PROVINCE.abbreviation
        ))
  );

  const SALES_REPS = PAGE_CONTENTS.wholesalerRetailerInquiries;

  const SalesRepLocatorControls = () => (
    <>
      <h3>Sales rep locator</h3>

      <p className="cp-heading-title">Independent accounts</p>

      <div className="cp-filter-select">
        <p className="cp-type">Country</p>
        <p className="cp-value">{repLocatorCountry}</p>

        <BsChevronDown />

        <select
          name="countries"
          onChange={(e) => updateCountry(e.target.value)}
        >
          {["All", "US", "Canada"].map((country) => (
            <option value={country} selected={repLocatorCountry === country}>
              {country}
            </option>
          ))}
        </select>
      </div>

      <div
        className={`cp-filter-select ${
          repLocatorCountry === "All" ? "cp-disabled" : ""
        }`}
      >
        <p className="cp-type">State/province</p>
        <p className="cp-value">{repLocatorStateProvince}</p>

        <BsChevronDown />

        <select
          name="provinces"
          disabled={repLocatorCountry === "All"}
          onChange={(e) => setRepLocatorStateProvince(e.target.value)}
        >
          <option value="All">All</option>

          {repLocatorCountry === "US" &&
            USED_STATES.map((STATE) => (
              <option value={STATE.abbreviation}>{STATE.name}</option>
            ))}

          {repLocatorCountry === "Canada" &&
            USED_PROVINCES.map((STATE) => (
              <option value={STATE.abbreviation}>{STATE.name}</option>
            ))}
        </select>
      </div>
    </>
  );

  return (
    <Layout
      //   location={this.props.location}
      //   title={siteTitle}
      HeaderExtension={
        <div className="header_extension no_bg contact aligncenter">
          <div className="bg">
            <div className="container">
              <Fade bottom duration={1000} delay={125} distance={"25%"}>
                <h2>How can we help you?</h2>
              </Fade>

              <Fade bottom duration={1000} delay={250} distance={"25%"}>
                <div className="cp-links">
                  <ScrollLink
                    to="consumer-inquiries"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    Consumer inquiries
                  </ScrollLink>

                  <ScrollLink
                    to="additional"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Additional help
                  </ScrollLink>

                  <ScrollLink
                    to="wholesale"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Wholesaler/retailer inquiries
                  </ScrollLink>

                  <ScrollLink
                    to="rep-locator"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    Sales rep locator
                  </ScrollLink>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      }
    >
      <SEO
        title="Contact"
        keywords={["Contact Upper Canada Soap"]}
        description="Contact us about Upper Canada Soap business inquiries and Upper Canada Soap careers. Discover the world’s best in personal care products and beauty innovation."
      />

      <div className="page-content pages-contact">
        <ScrollTarget name="consumer-inquiries">
          <div className="container section-form">
            <div className="columns">
              <div className="column is-4">
                <p className="cp-questions">
                  Questions? Concerns? Or just want to tell us your thoughts?
                  Send us a message!
                </p>

                <p>
                  If your inquiry is product related, please have the product ID
                  and UPC information ready.
                </p>
              </div>

              <div className="column is-1 is-wide-only"></div>

              <div className="column is-7">
                {!success && (
                  <>
                    <form
                      name="contact"
                      method="post"
                      action="/thanks/"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      data-netlify-recaptcha="true"
                      style={{
                        display: "block",
                        height: 0,
                        visibility: "hidden",
                        opacity: "0"
                      }}
                    >
                      <noscript>
                        <p>This form won’t work with Javascript disabled</p>
                      </noscript>

                      <input type="hidden" name="form-name" value="contact" />

                      {formItems.map((formItem) => (
                        <>
                          {!formItem.type && (
                            <input id={formItem.name} name={formItem.name} />
                          )}

                          {formItem.type === "textarea" && (
                            <textarea
                              id={formItem.name}
                              name={formItem.name}
                            ></textarea>
                          )}
                        </>
                      ))}
                    </form>

                    <FormValidation
                      onSubmit={handleSubmit}
                      config={config}
                      onSubmit={handleSubmit}
                      initialValues={{
                        subject: "general-inquiries"
                      }}
                    >
                      {({ fields, errors, submitted }) => (
                        <>
                          <div>
                            {formItems.map((formItem) => (
                              <div
                                className="field"
                                key={`formItem-${formItem.name}`}
                              >
                                <div className="control">
                                  {!formItem.type && (
                                    <input
                                      className={`input ${
                                        submitted && errors[formItem.name]
                                          ? "is-danger"
                                          : ""
                                      }`}
                                      id={`mc-${formItem.name}`}
                                      name={formItem.name}
                                      placeholder={formItem.title}
                                    />
                                  )}

                                  {formItem.type === "textarea" && (
                                    <textarea
                                      className={`textarea ${
                                        submitted && errors[formItem.name]
                                          ? "is-danger"
                                          : ""
                                      }`}
                                      id={`mc-${formItem.name}`}
                                      name={formItem.name}
                                      placeholder={formItem.title}
                                    ></textarea>
                                  )}
                                </div>

                                {submitted && errors[formItem.name] && (
                                  <p className="help is-danger">
                                    {errors[formItem.name]}
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>

                          <Recaptcha
                            ref={recaptchaRef}
                            sitekey={RECAPTCHA_KEY}
                            size="normal"
                            id="recaptcha-google"
                            onChange={() => setSubmitButtonDisabled(false)}
                            style={{ marginBottom: "1em" }}
                          />

                          <div>
                            <button
                              type="submit"
                              className={`button ${
                                loading ? "is-loading" : ""
                              }`}
							  disabled={submitButtonDisabled}
                            >
                              Submit <BsArrowRight />
                            </button>
                          </div>

                          {error && (
                            <div className="notification is-danger marginTop">
                              <span>Something went wrong</span>
                            </div>
                          )}
                        </>
                      )}
                    </FormValidation>
                  </>
                )}

                {success && (
                  <div className="cp-success">
                    <h3 style={{ marginBottom: "1em" }}>Success</h3>

                    <a
                      href="/"
                      className="button primary"
                      onClick={(e) => {
                        e.preventDefault();

                        if (typeof window !== "undefined") {
                          window.location.reload();
                        }
                      }}
                    >
                      Start a message again
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ScrollTarget>

        <ScrollTarget name="additional">
          <div className="section-additional">
            <div className="container">
              <div className="columns is-multiline">
                <div className="column is-narrow">
                  <p className="cp-title">Further inquiries</p>

                  <p className="cp-text">
                    Please contact Customer Service
                    <br />1 (800) 548-4097
                  </p>
                </div>

                <div className="column is-narrow">
                  <p className="cp-title">North American headquarters</p>

                  <a
                    href="https://goo.gl/maps/bSmr5HhCvkLazWjj9"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span>5875 Chedworth Way Mississauga ON L5R 3L9</span>
                    <RiArrowRightUpLine />
                  </a>

                  <p className="cp-text">
                    <a
                      href="mailto:email@uppercanadasoap.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span>email@uppercanadasoap.com</span>
                    </a>
                  </p>

                  <p className="cp-text">
                    Please contact Customer Service
                    <br />1 (800) 548-4097
                  </p>
                </div>

                <div className="column is-narrow">
                  <p className="cp-title">Visit UCS UK</p>

                  <a
                    href="https://danielle-ltd.co.uk"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span>www.danielle-ltd.co.uk</span>
                    <RiArrowRightUpLine />
                  </a>
                </div>

                <div className="column is-narrow">
                  <p className="cp-title">UK headquarters</p>
                  <a
                    href="https://goo.gl/maps/yk37pMPwu8tFzDhC9"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span>
                      Upper Canada UK Ltd.
                      <br />
                      10.1.1 The Leathermarket, Weston Street
                      <br />
                      London SE1 3ER
                    </span>
                    <RiArrowRightUpLine />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ScrollTarget>

        <ScrollTarget name="wholesale">
          <div className="section-locator" style={{ paddingBottom: 0 }}>
            <StickyContainer>
              <div className="container">
                <div className="columns">
                  <div className="column is-5 cp-controls">
                    <Sticky topOffset={-112}>
                      {({
                        style,
                        // the following are also available but unused in this example
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <div style={style} className="cp-sticky is-wide-only">
                          <h3>Sales</h3>

                          <p className="cp-heading-title">Wholesale accounts</p>
                        </div>
                      )}
                    </Sticky>

                    <div className="is-mobile-only cp-controls-mobile">
                      <h3>Sales</h3>

                      <p className="cp-heading-title">Wholesale accounts</p>
                    </div>
                  </div>

                  <div className="column is-7 cp-content">
                    {SALES_REPS.map((rep, i) => (
                      <div
                        className={`cp-card ${
                          i === SALES_REPS.length - 1
                            ? `cp-no-margin-bottom`
                            : ""
                        }`}
                      >
                        <>
                          {rep.name && <p className="cp-name">{rep.name}</p>}

                          {rep.role && <p className="cp-pos">{rep.role}</p>}

                          {rep.phoneNumber && (
                            <p className="cp-number">{rep.phoneNumber}</p>
                          )}

                          {rep.email && (
                            <a className="anchor" href={`mailto:${rep.email}`}>
                              {rep.email}
                            </a>
                          )}
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </StickyContainer>
          </div>
        </ScrollTarget>

        <ScrollTarget name="rep-locator">
          <div className="section-locator">
            <StickyContainer>
              <div className="container">
                <div className="columns">
                  <div className="column is-5 cp-controls">
                    <Sticky topOffset={-112}>
                      {({
                        style,
                        // the following are also available but unused in this example
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <div style={style} className="cp-sticky is-wide-only">
                          <SalesRepLocatorControls />
                        </div>
                      )}
                    </Sticky>

                    <div className="is-mobile-only cp-controls-mobile">
                      <SalesRepLocatorControls />
                    </div>
                  </div>

                  <div className="column is-7 cp-content">
                    {salesRepsToShow.map((repProvince, i) => (
                      <>
                        {(i === 0 ||
                          salesRepsToShow[i - 1].country !==
                            repProvince.country) && (
                          <h5 className="banner-heading">
                            {repProvince.country} REPS
                          </h5>
                        )}

                        <p className="cp-title">
                          {repProvince.name}{" "}
                          {repProvince.country !== "Canada" && (
                            <>
                              (
                              {repProvince.provinces
                                .map((province) => province.abbreviation)
                                .join(", ")}
                              )
                            </>
                          )}
                        </p>

                        {repProvince.groups.map((repGroup, i) => (
                          <div
                            className={`cp-card ${
                              repProvince.groups.length === 1
                                ? "cp-no-margin-bottom"
                                : ""
                            }`}
                          >
                            {repGroup.title && (
                              <p className="cp-card-title">{repGroup.title}</p>
                            )}

                            {repGroup.reps.map((rep, j) => (
                              <>
                                {j !== 0 && <div className="cp-divider"></div>}

                                {rep.name && (
                                  <p className="cp-name">{rep.name}</p>
                                )}
                                {rep.position && (
                                  <p className="cp-pos">{rep.position}</p>
                                )}
                                {rep.number && (
                                  <p className="cp-number">{rep.number}</p>
                                )}

                                {rep.email && (
                                  <a
                                    className="anchor"
                                    href={`mailto:${rep.email}`}
                                  >
                                    {rep.email}
                                  </a>
                                )}
                              </>
                            ))}
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </StickyContainer>
          </div>
        </ScrollTarget>
      </div>
    </Layout>
  );
}

export default Contact;
